import { useEffect, useState } from "react";

function useKeystroke(callback) {
  const [typed, setTyped] = useState("");
  const targetWord = "waterfall";

  useEffect(() => {
    function onKeyDown(event) {
      if (targetWord.startsWith(typed + event.key)) {
        setTyped((prevTyped) => prevTyped + event.key);

        if (typed + event.key === targetWord) {
          callback();
          setTyped("");
        }
      } else {
        setTyped("");
      }
    }

    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [typed, callback]);
}

export default useKeystroke;
