import React, { useState } from "react";
import SizeSelector from "./SizeSelector";
import PriceSlider from "./PriceSlider";
import PaymentDistributionSlider from "./PaymentDistributionSliders";

function PaymentMessageBuilder() {
  const [selectedSize, setSelectedSize] = useState("M");
  const [selectedPrice, setSelectedPrice] = useState(20);
  const [goochMoney, setGoochMoney] = useState(10);
  const [charityMoney, setCharityMoney] = useState(10);

  return (
    <div className="text-center mt-4 flex flex-row justify-center">
      <div className="flex flex-col gap-11 w-full  md:w-96 mx-4">
        <SizeSelector
          sizes={["S", "M", "L", "XL"]}
          selectedSize={selectedSize}
          onChange={(e) => setSelectedSize(e.target.value)}
        />
        <PriceSlider min={20} max={50} value={selectedPrice} onChange={setSelectedPrice} />
        {selectedPrice === 40 && (
          <PaymentDistributionSlider
            goochMoney={goochMoney}
            charityMoney={charityMoney}
            setGoochMoney={setGoochMoney}
            setCharityMoney={setCharityMoney}
          />
        )}
        <div className="text-4xl">Next Steps</div>
        <ol>
          <li>1. Venmo @goochvalley ${selectedPrice}</li>
          <li>2. Copy the below message and DM @goochvalley on Instagram or email goochvalley@gmail.com</li>
        </ol>
      </div>
    </div>
  );
}

export default PaymentMessageBuilder;
