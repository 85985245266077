import React from "react";

const Links = () => {
  return (
    <div className="text-left mt-4 ml-4">
      <h1 style={{ fontSize: 30 }}>Philosophy</h1>
      <div className="ml-4">
        <a href="https://www.ribbonfarm.com/2015/02/18/a-dent-in-the-universe/">A Dent in the Universe</a>
        <br />

        <a href="https://www.john-uebersax.com/plato/words/greek.htm">Ten Greek Philosophical Terms</a>
        <br />
        <a href="https://catholicgnosis.wordpress.com/2014/06/26/platonism-as-psychotherapy/">
          Platonism as Psychotherapy
        </a>
        <br />
        <a href="https://meaningness.com/">Meaningness</a>
      </div>
      <h1 style={{ fontSize: 30 }}>Fun</h1>
      <div className="ml-4">
        <a href="https://tvtropes.org/">TV Tropes</a>
        <br />
        <a href="https://idiomation.wordpress.com/a-z-of-entries/">Idiomation</a>
        <br />
        <a href="https://www.instagram.com/goochvalley/">Gooch Valley Instagram</a>
      </div>
      <h1 style={{ fontSize: 30 }}>Blog Recs</h1>
      <div className="ml-4">
        <a href="https://www.mosaicmeaning.com/">Mosaic Meaning</a>
        <br />
        <a href="https://tannertracht.substack.com/">Ultracrepidarian Hangs</a>
        <br />
        <a href="https://literarysundae.substack.com/">Ammuddo</a>
        <br />
        <a href="https://kylepeterson.substack.com/">Kyle's Newsletter</a>
      </div>
      <h1 style={{ fontSize: 30 }}>YouTube</h1>
      <div className="ml-4">
        <a href="https://www.youtube.com/@GoochValley">Gooch Valley</a>
        <br />
        <a href="https://youtu.be/eh7qvXfGQho">Daniel Schmachtenberger’s talk at Emergence</a>
        <br />
      </div>
      <h1 style={{ fontSize: 30 }}>Music</h1>
      <div className="ml-4">
        <a href="https://soundcloud.com/tannergooch">Tanner Gooch</a>
        <br />
        <a href="https://soundcloud.com/zen-buddhist-monks">Zen Buddhist Monks</a>
        <br />
      </div>
    </div>
  );
};

export default Links;
