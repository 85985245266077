import React from "react";

function SizeSelector({ sizes, onChange, selectedSize }) {
  return (
    <div className="flex flex-row gap-5 justify-center text-4xl">
      {sizes.map((size, index) => (
        <label key={index} className="flex flex-row content-center gap-1">
          <input
            type="radio"
            name="size"
            value={size}
            onChange={onChange}
            checked={size === selectedSize} // Check the radio button if the size matches the selectedSize
          />
          {size}
        </label>
      ))}
    </div>
  );
}

export default SizeSelector;
