import React from "react";

const CityCollegePage = () => {
  const fontSize = 20;

  return (
    <div className="text-center mt-4 overflow-y-auto">
      <div className=" mx-3">
        <p style={{ fontSize: 15 }}>The central question we’ll have to answer is:&nbsp;</p>
        <p style={{ fontSize: 50 }}>
          <strong>Why did we put City College on the Gooch Valley shirts?</strong>
        </p>
        <br />
        <p style={{ fontSize }}>
          Gooch Valley is an&nbsp;
          <strong>idea</strong>, a&nbsp;<strong>philosophy</strong>, a&nbsp;<strong>place</strong>
        </p>
        <p style={{ fontSize }}>
          To give a sense of
          <strong>&nbsp;place&nbsp;</strong>
          to Gooch Valley we want to build the lore of what sort of place it is.&nbsp;
        </p>
        <br />
        <p style={{ fontSize }}>What goes on there?&nbsp;</p>
        <p style={{ fontSize }}>What does it look like?&nbsp;</p>
        <p style={{ fontSize }}>What are the people like, etc.</p>
        <br />
        <p style={{ fontSize }}>
          I think with the
          <strong>&nbsp;Gooch Valley City College&nbsp;</strong>t shirt drop we are establishing that Gooch Valley is a
          <strong>&nbsp;College Town</strong>
        </p>
        <br />
        <p style={{ fontSize: 30 }}>What does it mean to be a college town?</p>
        <p style={{ fontSize, marginTop: 15, marginBottom: 15 }}>
          In a college town a large proportion of the culture is centered around a university or place of higher
          learning. This encompasses many things but some include
        </p>
        <p style={{ fontSize }}>Education 🎓</p>
        <p style={{ fontSize }}>Free Expression 🧠</p>
        <p style={{ fontSize }}>Youth 🧒</p>
        <p style={{ fontSize }}>Athletics 🏀</p>
        <p style={{ fontSize }}>Community 🫂</p>
        <br />
        <p style={{ fontSize }}>
          I think a reason we chose City College is because of nostalgia for the city college environment. I don’t think
          this should be interpreted as a yearning for the past but rather <strong>challenging</strong> the idea that
          life will never be as good as it was.&nbsp;
        </p>
        <br />
        <p style={{ fontSize: 25, marginBottom: "150px" }}>
          <strong>The idea is that the best of times are right now.</strong>
        </p>
      </div>
    </div>
  );
};

export default CityCollegePage;
