import React from "react";
import { Slider } from "@blueprintjs/core";

const VALUE_MESSAGES = {
  20: "At Cost. You just wanna rep the brand.",
  30: "Goooch. You wanna help pay our league fees and earn on a spot on the Sponsorship Wall.",
  40: "Gooooch. You wanna help pay our league fees and donate to charity and earn a spot on the Sponsorship Wall.",
};

const PriceSlider = ({ value, onChange }) => {
  const steps = [20, 30, 40];
  const stepSize = 1; // for 3 total steps

  const handleValueChange = (sliderValue) => {
    onChange(steps[sliderValue]);
  };

  return (
    <div className="flex flex-col text-lg">
      <div>How much you pay us</div>
      <Slider
        min={0}
        max={2}
        stepSize={stepSize}
        value={steps.indexOf(value)}
        onChange={handleValueChange}
        labelValues={[0, 2]}
        labelRenderer={(sliderValue) => `$${steps[sliderValue]}`}
      />
      <div className="text-4xl">{`$${value}`}</div>
      <div>{VALUE_MESSAGES[value]}</div>
    </div>
  );
};

export default PriceSlider;
