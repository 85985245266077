import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Model from './Model';

import tanner1 from "../images/modeling/tanner1.JPG";
import tanner2 from "../images/modeling/tanner2.JPG";
import tanner3 from "../images/modeling/tanner3.JPG";
import tanner4 from "../images/modeling/tanner4.JPG";
import tanner5 from "../images/modeling/tanner5.JPG";

import evan1 from "../images/modeling/evan1.JPG";
import evan2 from "../images/modeling/evan2.JPG";
import evan3 from "../images/modeling/evan3.JPG";
import { useNavigate, useParams } from 'react-router-dom';

const tannerImages = [tanner1, tanner2, tanner3, tanner4, tanner5];
const evanImages = [evan1, evan2, evan3];

const options = [
  { value: '', label: 'Select a model' },
  { value: 'tanner', label: 'Tanner' },
  { value: 'evan', label: 'Evan' }
];

const modelDetails = {
  Tanner: {
    firstName: 'Tanner',
    lastName: 'Tracht',
    height: '5ft 11in',
    location: 'Boise, ID',
    bio: 'Tanner has a vintage style and enjoys working with photographers who can capture that style in their images.'
  },
  Evan: {
    firstName: 'Evan',
    lastName: 'Lunning',
    height: '6ft 2in',
    location: 'Boise, ID',
    bio: 'Evan has a masculine build and enjoys working with photographers who can capture that in their images.'
  }
};

const ModelPage = () => {
  const { selectedModel } = useParams();
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const [modelInfo, setModelInfo] = useState({});

  useEffect(() => {
    if (selectedModel === 'tanner') {
      setImages(tannerImages);
      setModelInfo(modelDetails.Tanner);
    } else if (selectedModel === 'evan') {
      setImages(evanImages);
      setModelInfo(modelDetails.Evan);
    } else {
      setImages([...tannerImages, ...evanImages]);
      setModelInfo({});
    }
  }, [selectedModel]);

  const handleModelChange = (event) => {
    navigate(`/modeling/${event.value}`);
  };

  return (
    <div className='h-screen overflow-y-auto hide-scrollbar p-4'>
      <div className='flex justify-between items-center mb-4 pl-4 pr-4 pt-4'>
        <div className='flex-grow'>
          <h2 className='text-3xl text-white font-extrabold'>Gooch Valley Models</h2>
        </div>
        <Select
          options={options}
          onChange={handleModelChange}
          value={options.find(option => option.value === selectedModel)}
          className='w-48'
        />
      </div>
      <Model
        images={images}
        firstName={modelInfo.firstName}
        lastName={modelInfo.lastName}
        height={modelInfo.height}
        location={modelInfo.location}
        bio={modelInfo.bio}
        modelSelected={!!selectedModel}
      />
    </div>
  );
};

export default ModelPage;