import React from 'react';

const Accolades = () => {
  return (
    <div className='mt-4 text-center text-xl'>
      <ul>
        <li>2022 Boise B2 Summer League Champions</li>
        <li>2022-2023 Boise B3 Winter League Champions</li>
        <li>2023-2024 Boise B3 Winter League Runner-Ups</li>
      </ul>
    </div>
  );
};

export default Accolades;