import React from 'react';
import insta from "../icons/instagram.svg";
import youtube from "../icons/youtube.svg";

const Footer = () => {
  return (
    <footer style={{ backgroundColor: '#ff7066', textAlign: 'center', padding: '10px', position: 'fixed', left: '0', bottom: '0', width: '100%' }} className='flex flex-row justify-center'>
      <a href="https://www.instagram.com/goochvalley/" target="_blank" rel="noopener noreferrer">
        <img src={insta} alt="instagram" className="w-10 h-10 mr-2 cursor-pointer" />
      </a>
      <a href="https://www.youtube.com/@GoochValley" target="_blank" rel="noopener noreferrer">
        <img src={youtube} alt="youtube" className="w-10 h-10 mr-2 cursor-pointer" />
      </a>
    </footer>
  );
};

export default Footer;