import { Alignment, Button, Icon, Navbar as NavbarBp } from "@blueprintjs/core";
import tree from "../tree.png";


import React from "react";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();

  return (
    <NavbarBp
      style={{
        backgroundColor: "#fc5044",
      }}
      className="bp5-dark"
    >
      <NavbarBp.Group align={Alignment.LEFT}>
        <img src={tree} alt="tree" className="w-10 h-10 mr-2" />
        <NavbarBp.Heading className="lg:text-2xl sm:text-sm">Gooch Valley</NavbarBp.Heading>
        <NavbarBp.Divider />
        <Button
          className="bp5-minimal"
          icon={<Icon icon="home" color="white" />}
          text={<span className="button-text">Home</span>}
          textClassName="text-white"
          onClick={() => navigate("/")}
        />
        <Button
          className="bp5-minimal"
          icon={<Icon icon="trophy" color="white" />}
          text={<span className="button-text">Accolades</span>}
          textClassName="text-white"
          onClick={() => navigate("/accolades")}
        />
        <Button
          className="bp5-minimal"
          icon={<Icon icon="media" color="white" />}
          text={<span className="button-text">Slideshow</span>}
          textClassName="text-white"
          onClick={() => navigate("/slideshow")}
        />
        <Button
          className="bp5-minimal"
          icon={<Icon icon="team" color="white" />}
          text={<span className="button-text">Sponsors</span>}
          textClassName="text-white"
          onClick={() => navigate("/sponsors")}
        />
        <Button
          className="bp5-minimal"
          icon={<Icon icon="link" color="white" />}
          text={<span className="button-text">Links</span>}
          textClassName="text-white"
          onClick={() => navigate("/links")}
        />
      </NavbarBp.Group>
      <NavbarBp.Group align={Alignment.RIGHT}>

      </NavbarBp.Group>
    </NavbarBp>
  );
};

export default Navbar;
