import React from 'react';

function Model({ images, firstName, lastName, height, location, bio, modelSelected }) {
  return (
    <div className="p-4">
      {modelSelected && (
        <div className="mb-4 p-6 rounded-lg shadow-lg" style={{ backgroundColor: "#fc5044" }}>
          <h1 className="text-3xl font-extrabold text-gray-800 mb-2">{firstName} {lastName}</h1>
          <p className="text-lg text-gray-600 mb-1"><span className="font-semibold">Height:</span> {height}</p>
          <p className="text-lg text-gray-600 mb-1"><span className="font-semibold">Location:</span> {location}</p>
          <p className="text-lg text-gray-600"><span className="font-semibold">Bio:</span> {bio}</p>
        </div>
      )}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {images.map((image, index) => (
          <div key={index} className="w-full h-full">
            <img src={image} alt={`${index}`} className="object-cover w-full h-full rounded-lg shadow-md" />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Model;