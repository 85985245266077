import React from "react";
import { Slider } from "@blueprintjs/core";

const PaymentDistributionSliders = ({ goochMoney, setGoochMoney, charityMoney, setCharityMoney }) => {
  const handleChange = (name, value) => {
    if (name === "gooch") {
      setGoochMoney(value);
      setCharityMoney(20 - value);
    } else if (name === "charity") {
      setCharityMoney(value);
      setGoochMoney(20 - value);
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="text-2xl">Customize your contribution</div>
      <div className="flex flex-row gap-2 ">
        <div className="flex flex-col text-lg flex-1">
          <div className="text-center">Gooch Valley</div>
          <Slider
            min={1}
            max={19}
            value={goochMoney}
            onChange={(value) => handleChange("gooch", value)}
            showTrackFill={true}
            labelRenderer={false}
          />
          <div className="text-4xl">{`$${goochMoney}`}</div>
        </div>
        <div className="flex flex-col text-lg flex-1">
          <div className="text-center">Charity</div>
          <Slider
            min={1}
            max={20}
            value={charityMoney}
            onChange={(value) => handleChange("charity", value)}
            showTrackFill={true}
            labelRenderer={false}
          />
          <div className="text-4xl">{`$${charityMoney}`}</div>
        </div>
      </div>
    </div>
  );
};

export default PaymentDistributionSliders;
