import React from "react";

import tanner from "../images/tanner.jpg";
import tanner2 from "../images/action1.jpg";
import tanner3 from "../images/brandon1.jpg";
import tanner4 from "../images/evan1.jpg";
import tanner5 from "../images/evan2.jpg";
import tanner6 from "../images/evan3.jpg";
import tanner7 from "../images/goochvalley1.jpg";
import tanner8 from "../images/goochvalley2.jpg";
import tanner9 from "../images/hunter1.jpg";
import tanner10 from "../images/joel1.jpg";
import tanner11 from "../images/evan4.JPG";
import tanner12 from "../images/evanhunter.JPG";
import tanner13 from "../images/evankyle.JPG";
import tanner14 from "../images/hunter2.JPG";
import tanner15 from "../images/kyle1.JPG";
import tanner16 from "../images/tannerevan.JPG";

import { Slide } from "react-slideshow-image";

const IMAGES = [
  tanner,
  tanner2,
  tanner3,
  tanner4,
  tanner5,
  tanner6,
  tanner7,
  tanner8,
  tanner9,
  tanner10,
  tanner11,
  tanner12,
  tanner13,
  tanner14,
  tanner15,
  tanner16,
];

const Slideshow = () => {
  return (
    <Slide>
      {IMAGES.map((image, index) => {
        return (
          <div className="each-slide-effect">
            <div style={{ backgroundImage: `url(${image})` }}></div>
          </div>
        );
      })}
    </Slide>
  );
};

export default Slideshow;
