import React from "react";

const About = () => {
  return (
    <div className="mt-4 text-center">
      {/* <p className="text-center">
        Gooch Valley is about basketball. <br />
        Gooch Valley is about community. <br />
        Gooch Valley is about rock climbing. <br />
        Gooch Valley is about sincerity. <br />
        Gooch Valley is about creation. <br />
      </p> */}
      <div
        role="status"
        class="max-w-sm animate-pulse h-2.5 bg-gray-200  dark:bg-gray-700 w-48 mb-4"
        style={{ height: 600, width: 300, display: "flex", justifyContent: "center", alignItems: "center" }}
      />
    </div>
  );
};

export default About;
