import React, { useContext } from "react";
import { UnlockedContext } from "../App";
import PaymentMessageBuilder from "./PaymentMessageBuilder";

import GOOCH_VALLEY_FALL from "../images/gvwinter.png";

const Home = () => {
  const unlocked = useContext(UnlockedContext);

  if (unlocked) return <PaymentMessageBuilder />;

  return (
    <div className="text-center mt-4 overflow-y-auto">
      <div className="mt-4">
        <img src={GOOCH_VALLEY_FALL} alt="Gooch Valley Team" className="mx-auto" />
      </div>
    </div>
  );
};

export default Home;
