import "./App.css";

import React, { createContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Analytics } from "@vercel/analytics/react";

import Home from "./components/Home";
import About from "./components/About";
import Accolades from "./components/Accolades";
import Slideshow from "./components/Slideshow";
import Navbar from "./components/Navbar";
import useKeystroke from "./hooks/useKeystroke";
import Sponsors from "./components/Sponsors";
import Links from "./components/Links";
import CityCollegePage from "./components/CityCollegePage";
import Footer from "./components/Footer";
import ModelPage from "./components/ModelPage";

export const UnlockedContext = createContext();

function App() {
  const [unlocked, setUnlocked] = React.useState(false);

  useKeystroke(() => {
    setUnlocked(true);
  });

  return (
    <BrowserRouter>
      <Analytics />
      <div className="h-full flex flex-col" style={{ backgroundColor: "#ff7066" }}>
        <Navbar />
        <UnlockedContext.Provider value={unlocked}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/accolades" element={<Accolades />} />
            <Route path="/slideshow" element={<Slideshow />} />
            <Route path="/sponsors" element={<Sponsors />} />
            <Route path="/links" element={<Links />} />
            <Route path="/city-college" element={<CityCollegePage />} />
            <Route path="/modeling/:selectedModel?" element={<ModelPage />} />
          </Routes>
        </UnlockedContext.Provider>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
